import '../../styles/base/_fonts.scss';
import { downloadApp } from '../../services/downloadApp';
import { installOnIos, installOnAndroid } from '../../constants/imageUrls';
import './errorpage.scss';
import { thriveLogoUrl } from '../../constants/imageUrls';

const ErrorPage = () => {

  return (
    <div className='error-page'>
      <img src={require('../../assets/img/error.png')} alt='error' width="250" height="250" className='error-image' />
      <h1 className='sofia-sans-extra-bold text-center'>Sorry! That page does not exist.</h1>
      <p className='poppins-regular text-center grey-text'>But if it’s good food you’re looking for, we know where to find it.</p>
      <p className='poppins-regular text-center grey-text'>Download <img src={thriveLogoUrl} alt="thrive logo" height="17" width="48" /> to dive into Mumbai’s best restaurant reccos and avail exciting discounts!</p>
      <div className='flex storelinks'>
        <img onClick={(e) => downloadApp(e, "ios", null)} src={installOnIos} width="126" height="37" />
        <img onClick={(e) => downloadApp(e, "android", null)} src={installOnAndroid} width="126" height="37" />
      </div>
    </div>
  );
};

export default ErrorPage;