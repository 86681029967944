import React, { useState, useContext } from "react";
import './bottomdrawer.scss';
import { BottomDrawerContext } from "../../App";

const BottomDrawer = () => {
  const { isOpen, content, handleCloseDrawer } = useContext(BottomDrawerContext);

  return (
    <div className={`bottom-drawer ${isOpen ? 'open' : ''}`}>
      <div className="flex close-button" onClick={handleCloseDrawer}>
        &times;
      </div>
      {isOpen && (
        <div className="content">
          {content}
        </div>
      )}
    </div>
  );
};

export default BottomDrawer;
