import { createContext, useReducer } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ListDetailsPage from './containers/ListDetailsPage/Loadable';
import PostDetailsPage from './containers/PostDetailsPage/Loadable';
import QuestionDetailsPage from './containers/QuestionDetailsPage/Loadable';
import RestaurantInfoDeepView from './containers/RestaurantInfoDeepView/Loadable';
import BottomDrawer from './common/BottomDrawer/index';
import ErrorPage from './views/ErrorPage';

export const BottomDrawerContext = createContext();

const BottomDrawerProvider = ({ children }) => {
  const [drawerContent, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "OPEN":
        return {
          isOpen: true,
          content: action.content,
        };
      case "CLOSE":
        return {
          isOpen: false,
          content: null,
        };
      default:
        return state;
    }
  }, {
    isOpen: false,
    content: null,
  });

  const handleOpenDrawer = (content) => {
    document.body.classList.add('no-scroll');
    dispatch({ type: "OPEN", content });
  };

  const handleCloseDrawer = () => {
    document.body.classList.remove('no-scroll');
    dispatch({ type: "CLOSE" });
  };

  return (
    <BottomDrawerContext.Provider value={{ isOpen: drawerContent.isOpen, content: drawerContent.content, handleOpenDrawer, handleCloseDrawer }}>
      {children}
    </BottomDrawerContext.Provider>
  );
};

function App () {
  return (
    <BottomDrawerProvider>
      <Router>
        <Routes>
          <Route path='/lists/:listSlug' element={<ListDetailsPage />} />
          <Route path='/posts/:postSlug' element={<PostDetailsPage />} />
          <Route path='/questions/:questionSlug' element={<QuestionDetailsPage />} />
          <Route path='/deepview' element={<RestaurantInfoDeepView />} />
          <Route path='/*' element={<ErrorPage />} />
        </Routes>
        <BottomDrawer />
      </Router>
    </BottomDrawerProvider>
  );
}

export default App;
